// MEDIA QUERY MANAGER
/*

/*
0 - 600px:      Phone
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet landscape
[1200 - 1800] is where our normal styles apply
1800px + :      Big desktop
$breakpoint arguement choices:
- phone
- tab-port
- tab-land
- big-desktop
ORDER: Base + typography > general layout + grid > page layout > components
1em = 10px

xs	'screen and (max-width: 599px)'
sm	'screen and (min-width: 600px) and (max-width: 959px)'
md	'screen and (min-width: 960px) and (max-width: 1279px)'
lg	'screen and (min-width: 1280px) and (max-width: 1919px)'
xl	'screen and (min-width: 1920px) and (max-width: 5000px)'
lt-sm	'screen and (max-width: 599px)'
lt-md	'screen and (max-width: 959px)'
lt-lg	'screen and (max-width: 1279px)'
lt-xl	'screen and (max-width: 1919px)'
gt-xs	'screen and (min-width: 600px)'
gt-sm	'screen and (min-width: 960px)'
gt-md	'screen and (min-width: 1280px)'
gt-lg	'screen and (min-width: 1920px)'
*/
@mixin respond($breakpoint) {
  @if $breakpoint == xs {
    @media only screen and (max-width: 599px) {
      @content;
    }
  }
  @if $breakpoint == sm {
    @media only screen and (min-width: 600px) and (max-width: 959px) {
      @content;
    }
  }
  @if $breakpoint == md {
    @media only screen and (min-width: 960px) and (max-width: 1279px) {
      @content;
    }
  }
  @if $breakpoint == lg {
    @media only screen and (min-width: 1280px) and (max-width: 1919px) {
      @content;
    }
  }
  @if $breakpoint == xl {
    @media only screen and (min-width: 1920px) and (max-width: 5000px) {
      @content;
    } //600px
  }
  @if $breakpoint == lt-sm {
    @media only screen and (max-width: 599px) {
      @content;
    }
  }
  @if $breakpoint == lt-md {
    @media only screen and (max-width: 959px) {
      @content;
    }
  }
  @if $breakpoint == lt-lg {
    @media only screen and (max-width: 1279px) {
      @content;
    }
  }
  @if $breakpoint == lt-xl {
    @media only screen and (max-width: 1919px) {
      @content;
    }
  }
  @if $breakpoint == gt-xs {
    @media only screen and (min-width: 600px) {
      @content;
    } //1200px
  }
  @if $breakpoint == gt-sm {
    @media only screen and (min-width: 960px) {
      @content;
    } //1800
  }
  @if $breakpoint == gt-md {
    @media only screen and (min-width: 1280px) {
      @content;
    } //1800
  }
  @if $breakpoint == gt-lg {
    @media only screen and (min-width: 1920px) {
      @content;
    } //1800
  }
  @if $breakpoint == smartphones {
    /* Smartphones (portrait and landscape) ----------- */
    @media only screen and (max-width: 480px) {
      /* Styles */
      @content;
    }
  }
  @if $breakpoint == smartphones-landscape {
    /* Smartphones (portrait and landscape) ----------- */
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
      /* Styles */
      @content;
    }
  }
  @if $breakpoint == iphone-678 {
    /* iPhone 6+, 7+, 8+ ----------- */
    @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
      /* Styles */
      @content;
    }
  }
  @if $breakpoint == iphone-x {
    /* iPhone XS Max, XR ----------- */
    @media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
      /* Styles */
      @content;
    }
  }
}

@mixin sub-content {
  font-size: 5rem;
  @include respond(lt-xl) {
    font-size: 4rem;
  }
  @include respond(lt-md) {
    font-size: 3.5rem;
  }
  @include respond(lt-sm) {
    font-size: 3rem;
  }
  @include respond(smartphones) {
    font-size: 2.5rem;
  }
}


