.flex-r{
    display:flex;
    flex-direction: row;
}

.flex-c{
    display:flex;
    flex-direction: column;
}

.padding-v-1 {
    padding: 1rem 0;
}

.relative {
    position: relative;
}

.text-align-center{
    text-align: center;
}