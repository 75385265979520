@import "main.scss";  

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
  width: 100%;
  background-color: black;
}

.black {
  background-color: black;
}
.full {
  height: 100%;
  width: 100%;
}

.height-50vh {
  height: 50vh;
}
.height-100vh {
  height: 100vh;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

div.std-cmp-height {
  height: 90vh;
}

.padding-5 {
  padding: 5%;
}

/*Overriding Bootstrap Styles*/
.bg-pink {
  background-color: #f3969a !important;
}

.border-pink {
  border-color: #f3969a !important;
}

.stroke {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  color: #0c0b0b;
  -webkit-text-stroke: 1px #e5e5e5;
}

.roboto {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
}

.stroke {
  color: #0c0b0b;
  -webkit-text-stroke: 1px #e5e5e5;
}

.white {
  color: white;
}
